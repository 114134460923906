<template>
  <validation-observer ref="observer" rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @close="onClose"
      submit="Submit"
      button-class="bg-dynamicBackBtn text-white"
      @submit="handleSubmit(onSubmit)"
      :disabled-button="isFetching"
      :loading="isFetching"
      width="width:520px"
    >
      <template v-slot:title>
        <span v-if="isOpenLeave" class="text-darkPurple font-bold text-lg">Add Leave</span>
        <div v-else-if="isOpenSelectEmployee" class="flex justify-start items-center gap-0">
          <back-button
            @onClick="(isOpenLeave = true, isOpenSelectEmployee = false)"
            variant="secondary"
            style="padding: 0 !important;color:white;"
          />
          <span class="text-darkPurple font-bold text-lg -ml-2">All Employees</span>
        </div>
      </template>
      <div v-if="isOpenLeave" class="flex flex-col justify-start gap-6">
        <p class="text-base text-darkPurple font-normal mb-0">Create a new leave plan.</p>
        <div class="flex flex-col jusity-start gap-5 border-b border-dashed border-romanSilver pb-5">
          <c-select
            v-model="payload.categoryId"
            :options="leaveCategory"
            :rules="['required']"
            :label="computedCategoryLabel"
            class="select-class"
          />
          <div class="flex flex-col justify-start gap-2">
            <span class="text-sm text-darkPurple font-normal">Details</span>
            <c-textarea v-model="payload.requestReason" :rules="['required']" :required="true" />
          </div>
          <div class="flex flex-col justify-start gap-2">
            <span class="text-sm text-darkPurple font-normal">Duration</span>
            <div class="flex justify-between items-center gap-4">
              <validation-provider class="w-full" name="Start Date" :rules="{required: true}" v-slot="{ errors }">
                <div :class="errors[0] ? 'flex items-center gap-0 px-4 border border-desire rounded-md' : 'date-flex flex items-center gap-0 px-4'">
                  <span><c-icon icon-name="calendar" size="xs" /></span>
                  <datepicker
                    placeholder="--From--"
                    input-class="date-input"
                    :rules="['required']"
                    style="width:100%"
                    v-model="payload.requestStartDate"
                  />
                </div>
                <small class="text-desire">{{ errors[0] }}</small>
              </validation-provider>
              <span class="text-base whitespace-nowrap text-romanSilver">--to--</span>
              <validation-provider class="w-full" name="End Date" :rules="{required: true}" v-slot="{ errors }">
                <div :class="errors[0] ? 'flex items-center gap-0 px-4 border border-desire rounded-md' : 'date-flex flex items-center gap-0 px-4'">
                  <span><c-icon icon-name="calendar" size="xs" /></span>
                  <datepicker
                    style="width:100%;"
                    placeholder="--To--"
                    input-class="date-input"
                    calendar-class="-ml-44"
                    :rules="['required']"
                    :disabled-dates="{ to: new Date(payload.requestStartDate) }"
                    v-model="payload.requestEndDate"
                  />
                </div>
                <small class="text-desire">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="flex flex-col justify-start items-start">
            <div class="w-full flex justify-center items-center gap-2 bg-ghostWhite rounded-md p-2">
              <span class="font-bold text-xl text-romanSilver">{{ computedWorkingDays }}</span>
              <span class="text-jet text-base font-normal">Days</span>
            </div>
            <div class="text-desire font-semibold">
              <template v-if="payload.daysRequested && activeCategory">
                <small v-if="payload.daysRequested > activeCategory.balance">
                  Leave days exceed leave balance
                </small>
              </template>
            </div>
          </div>
        </div>

        <div class="flex flex-col justify-start gap-3">
          <p class="font-semibold text-base text-darkPurple">Delegatee</p>
          <div @click="isOpenLeave = false, isOpenSelectEmployee = true">
            <div class="flex justify-start items-center gap-2 cursor-pointer rounded-md hover:bg-ghostWhite p-2">
              <span><c-icon icon-name="icon-arrow-right" size="xs" class="text-flame" /></span>
              <span class="font-semibold text-base text-flame">Select Employee</span>
            </div>
          </div>
          <template v-if="Object.keys(payload.delegatee).length">
            <c-card class="flex justify-start items-center gap-2 p-4 h-16 relative">
              <span v-if="payload.delegatee.photo" class="relative flex justify-center items-center w-12 h-12">
                <img :src="payload.delegatee.photo" class="relative w-12 h-12 rounded-full" alt="profile photo" />
              </span>
              <div v-else class="flex justify-center items-center w-12 h-12 rounded-full border">
                <span class="text-blueCrayola text-center font-semibold text-xl p-3">
                  {{ $getInitials(`${payload.delegatee.fname} ${payload.delegatee.lname}`) }}
                </span>
              </div>
              <div class="flex flex-col justify-start gap-1">
                <span class="font-semibold text-darkPurple text-base">
                  {{payload.delegatee.fname }} {{ payload.delegatee.lname }}
                </span>
                <span class="font-normal text-romanSilver text-xs uppercase">
                {{ payload.delegatee.userDesignation && `${payload.delegatee.userDesignation.name}, ` }}
                {{payload.delegatee.orgFunction && payload.delegatee.orgFunction.name }}
                </span>
              </div>
            </c-card>
          </template>
        </div>
      </div>
      <div v-else-if="isOpenSelectEmployee" class="flex flex-col justify-start gap-4">
        <div class="flex flex-col justify-start gap-4 border-b border-dashed border-romanSilver pb-8">
          <span class="font-semibold text-base text-darkPurple">Select a delegatee...</span>
          <search-input @input="onQueryFn({ search: $event, perPage: 5 }, null, onSearch)" placeholder="Search" />
        </div>
        <div class="flex flex-col justify-start gap-4">
          <span class="font-semibold text-base text-romanSilver">Results</span>
          <loader v-if="isFetchingEmployees" size="xxs" :loader-image="false" />
          <div v-else class="flex flex-col justify-start items-start gap-2 w-full divide-y divide-romanSilver divide-dashed">
            <div v-for="(employee) in employeesOptions" :key="employee.id" class="w-full flex flex-col justify-center items-start pt-2">
              <div class="w-full border border-transparent hover:border hover:border-flame rounded-md" :class="{'border border-flame': payload.delegatee.id === employee.id}">
                <div class="w-full flex items-center gap-2 p-2" @click="payload.delegatee = employee, isOpenLeave = true, isOpenSelectEmployee = false">
                  <div class="flex justify-center w-9 h-9 rounded-md">
                    <img class="rounded-md w-9 h-9" :src="employee.photo" v-if="employee.photo" />
                    <span v-else class="w-9 h-9 text-blueCrayola border text-center font-semibold rounded-md p-1 flex justify-center items-center">
                      {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
                    </span>
                  </div>
                  <div class="flex flex-col flex-grow justify-center items-start">
                    <span class="text-base text-darkPurple font-normal mb-0">{{ `${employee.fname} ${employee.lname}` }}</span>
                    <div class="flex flex-row justify-start items-center gap-2">
                      <span class="text-xs flex font-semibold text-romanSilver uppercase mb-0">{{ employee.orgFunction ? employee.orgFunction.name : '-' }}</span>
                      <span class="text-xs flex font-semibold text-romanSilver uppercase mb-0">{{ employee.userDesignation && `• ${employee.userDesignation.name}` }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RightSideBar>
  </validation-observer>
</template>

<script>
import { format } from "date-fns"
import Datepicker from "vuejs-datepicker"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input"
import RightSideBar from "@/components/RightSideBar"
import CIcon from "@/components/Icon"

export default {
components: {
    Datepicker,
    ValidationObserver,
    ValidationProvider,
    BackButton,
    CSelect,
    CCard,
    CTextarea,
    RightSideBar,
    SearchInput,
    CIcon,
  },
  data(){
    return {
      isOpen: false,
      isFetching: true,
      isFetchingEmployees: true,
      isOpenLeave: true,
      isOpenSelectEmployee: false,
      employeesOptions: [],
      leaveCategory: [],
      workingDaysConfig: {},

      payload: {
        categoryId: null,
        requestReason: null,
        requestStartDate: null,
        requestEndDate: null,
        daysRequested: 0,
        allowanceIsRequested: false,
        delegationUserId: null,
        delegatee: {},
      }
    }
  },
  computed: {
    computedWorkingDays(){
     if (this.payload.requestStartDate && this.payload.requestEndDate) {
        const workingDays = this.getWorkingDays(
          new Date(this.payload.requestStartDate),
          new Date(this.payload.requestEndDate),
          this.workingDaysConfig
        )
        return workingDays.length
      } return 0

    },
    activeCategory(){
      return this.leaveCategory.find(v => v.id === this.payload.categoryId)
    },
    computedCategoryLabel() {
      if (this.payload.categoryId) {
        const category = this.leaveCategory.find(v => v.id === this.payload.categoryId)
        return `Leave Type ( Balance: ${category.balance} Day(s) )`
      } return 'Leave Type ( Select Category to see balance )'
    }
  },
  watch: {
    computedWorkingDays(newValue){
      this.payload.daysRequested = newValue
    }
  },
  methods: {
    onQueryFn(queryParams, payload, callback){
      clearTimeout(this.setQueryTimeout);
      this.setQueryTimeout = setTimeout(() => {
        if(queryParams === undefined){
          this.queryParams = {}
          return callback({ ...this.queryParams, ...payload })
        }
        this.queryParams = {...this.queryParams, ...queryParams}
        return callback({ ...this.queryParams, ...payload })
      }, 1000);
    },
    async onOpen(leaveInfo){
      this.isOpen = true
      await this.getLeaveCategory()
      await this.getOrgPaySchedule()

      if (leaveInfo) {
        this.payload = { ...leaveInfo, categoryId: leaveInfo?.category?.id }
      }
      this.isFetching = false
    },
    onClose(){
      Object.assign(this.$data.payload, this.$options.data().payload)
      this.isFetching = true
      this.isOpen = false
    },
    onSearch(queryParams) {
      this.isFetchingEmployees = true
      this.$_getOrganisationEmployees(queryParams).then(({ data }) => {
        this.employeesOptions = data.data.employees
        this.isFetchingEmployees = false
      })
    },
    onSubmit(){
      this.isFetching = true

      const payload = {
        ...this.payload,
        orgId: this.$orgId,
        userId: this.$AuthUser.id,
        delegationUserId: this.payload.delegatee.id,
        requestStartDate: format(new Date(this.payload.requestStartDate), 'yyyy-MM-dd'),
        requestEndDate: format(new Date(this.payload.requestEndDate), 'yyyy-MM-dd'),
      }

      if (this.payload.id) {

        this.$_updateLeaveRequest({ requestId: this.payload.id, payload}).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 })
          this.$emit('submit')
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error, { duration: 3000 })
          this.isFetching = false
        })
      } else {
        this.$_createLeaveRequest(payload).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 })
          this.$emit('submit')
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error, { duration: 3000 })
          this.isFetching = false
        })
      }
    },
    getLeaveCategory() {
      const payload = { userId: this.$AuthUser.id }
      this.$_getEmployeeCategory(payload).then(({ data }) => {
        this.leaveCategory = data.leaveCategories
      })
    },
    async getOrgPaySchedule() {
      await this.$_getPaySchedules().then(({ data }) => {
        this.workingDaysConfig = data.data[0].weeklyWorkingDays
      });
    },
    getWorkingDays(startDate, endDate, workingDays) {
      const workingDaysValues = workingDays.filter((day) => day.state).map((day) => day.value);
      return Array.from(
        { length: (endDate - startDate) / (1000 * 60 * 60 * 24) + 1 },
        (_, index) => new Date(startDate.getTime() + index * (1000 * 60 * 60 * 24))
      ).filter((date) => workingDaysValues.includes(date.getDay()));
    },
  },
  created(){
    this.onSearch({ search: '', perPage: 5 });
    this.getLeaveCategory()
  }

}
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
</style>